<template>
  <ion-page class="page scrollable bg-transparent">
    <div class="d-flex justify-content-between align-items-center flex-wrap py-3">
      <div class="title">
        <router-link :to="{ name: 'profile', params: { username } }" class="clickable-item-hov">{{
          username
        }}</router-link
        >'s Store
      </div>
    </div>

    <div v-if="get(allListings, 'length')" class="row">
      <div
        v-for="(listing, index) in allListings"
        :key="index"
        class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch"
      >
        <router-link :to="{ name: 'mp-listing-details', params: { slug: listing.slug } }">
          <ion-card class="listing-card mx-0 mb-3">
            <div class="h-100">
              <div class="one-listing">
                <div class="d-flex justify-content-center">
                  <router-link
                    :to="{
                      name: 'mp-listing-details',
                      params: { slug: listing.slug },
                    }"
                  >
                    <img
                      loading="lazy"
                      v-image
                      :src="resizeUpload(listing.visuals[0], '190h')"
                      class="clickable-item-hov"
                    />
                  </router-link>
                </div>

                <div class="px-2 pt-2 pb-3">
                  <div class="name d-flex justify-content-center text-center clickable-item-hov mb-3">
                    {{ listing.title }}
                  </div>

                  <div class="d-flex justify-content-between">
                    <div class="user">{{ listing.seller.username }}</div>
                    <div class="baseprice">
                      <span v-if="listing.base_price === 0">Free</span>
                      <span v-else>${{ listing.base_price }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ion-card>
        </router-link>
      </div>
    </div>

    <div v-if="nextpageExits && !isLoading" class="d-flex justify-content-center">
      <p class="clickable-item-hov" @click="requestLoadMore">Load More</p>
    </div>

    <div v-if="isLoading" class="d-flex justify-content-center align-items-center">
      <ChLoading size="sm" class="spinner" />
    </div>
    <div v-else-if="!get(allListings, 'length')" class="text-center my-4">No Active Listings.</div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action, Getter } from 's-vuex-class';
import { toast } from '@/shared/native';
import { getUserMarketplaceListings, getNextPage } from '@/shared/actions/marketplace';
import namespace from '@/shared/store/namespace';
import { Paging, User } from '@/shared/types/static-types';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'UserListings',
  components: {},
})
export default class UserListings extends Vue {
  public allListings: any[] = [];
  public isLoading = true;
  public paging: Paging | null = null;
  public user: User | null = null;
  resizeUpload = resizeUpload;
  get = get;

  @Action('getUserByUsername', { namespace: namespace.UsersModule })
  public getUserByUsername!: any;

  @Getter('byUsername', { namespace: namespace.UsersModule })
  findUserByUsername!: (username: string) => User;

  public get username() {
    const router = useRouter();
    return router.currentRoute.value.params.username;
  }

  public async fetchMPListings(page = 1) {
    this.isLoading = true;

    try {
      const { results, ...paging } = await getUserMarketplaceListings(this.user!.id, page);

      this.allListings = results;
      this.paging = paging;
    } catch (e) {
      await toast.show('Could not load listings. Please try again.', 'nonative', 'danger');
    }

    this.isLoading = false;
  }

  public async requestLoadMore(ev: CustomEvent) {
    this.isLoading = true;

    try {
      const { results, ...paging } = await getNextPage(this.paging!);
      this.allListings = this.allListings.concat(results);
      this.paging = paging;
    } catch (e) {
      await toast.show('Could not load listings. Please try again.', 'nonative', 'danger');
    }

    this.isLoading = false;
  }

  public get nextpageExits() {
    return !!this.paging?.next;
  }

  public async mounted() {
    await this.getUserByUsername(this.username);
    this.user = this.findUserByUsername(this.username);

    this.fetchMPListings();
  }
}
</script>

<style lang="sass" scoped>
.page
  overflow-x: hidden
.name
  font-size: 18px
  font-weight: bold
  color: #737373
  cursor: pointer
  .name:hover
    opacity: 0.7

.new-listing-btn
  color: #214163
  font-size: 14px
  font-weight: bold
.title
  color: #214163
  font-size: 24px
  font-weight: bold
  a
    color: #214163
.one-listing
  img
    height: 130px
    width: 100vh !important
    object-fit: cover

.baseprice
  font-size: 18px
  font-weight: bold

.listing-card
  height: calc(100% - 1.5rem) !important
</style>
